<template>
    <label  v-if="label">{{ label }}</label>
    <input 
         v-bind="$attrs"
        :value="modelValue"
        :placeholder="label"
        @input="$emit('update:modelValue', $event.target.value)"
        class="field"
    />  
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default:'' 
        },
       modelValue: {
            type: [String, Number],
            default: ''
       }
   }
}
</script>

<style scoped>
.field{
        margin-bottom: 24px;
    }
input{
  padding: 5
}
</style>